import axios from 'axios'
import CustomTextProgressbar from '../seo/customTextProgressbar'

// get API gateway URL
function getApiUrl(){
  let api_url = ''
  if(window.location.host === 'tools.audit.enterpriseapplicationdevelopers.com:3000')
    api_url = "https://r8sbh13wv3.execute-api.us-east-2.amazonaws.com/production/report/"
  else if(window.location.host === 'audit.thecommerceshop.com' || window.location.host === 'audit.drupalpartners.com')
    api_url = "https://api.thecommerceshop.com/report/"
  else
    // api_url = "https://api.thecommerceshop.com/report/"
  api_url = "https://r8sbh13wv3.execute-api.us-east-2.amazonaws.com/production/report/"
  return api_url
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function fetchData(url,renderCount,setRenderCount){
  let response;
  try {
    response = await axios.get(url);
  } catch (error) {
    await sleep(2000)
    setRenderCount(renderCount+1)
    console.error(error);
  }
  return response;
}

function getNotUpdatedReports(completed_reports, already_updated_reports)
{
  return completed_reports.filter(x => !already_updated_reports.includes(x)).concat(already_updated_reports.filter(x => !completed_reports.includes(x)));
}

// Get data from the API
const getData = async (report_id,setNoReports,setReports,completedAudit,setCompletedAudit,setLoaderTxt,auditDate,setAuditDate,auditUrl,setAuditUrl,setAuditDetails,toast,selectedAudit,setSelectedAudit,renderCount,setRenderCount,percentage,setPercentage,setIsReportFullyLoaded,setMainTabIndex,setSubTabIndex,setRedirectInfo,redirectInfo,setIsShowRecommendations,setIsBulk) => {
  
  let api_url = getApiUrl()

  let audits = {
    'psi-mobile': 'Mobile performance test',
    'psi-desktop': 'Desktop performance test',
    'ylb-mobile': 'Mobile best practices test',
    'ylb-desktop': 'Desktop best practices test',
    'ser': 'SEO test',
    'sec': 'Security test'
  }

  if(selectedAudit.length === 0)
  {
    let selected_types_api_url = api_url+'?result='+report_id+'&target=available-types'
    let selected_types_response = await fetchData(selected_types_api_url,renderCount,setRenderCount)
    let redirect_info_api_url = api_url+'?result='+report_id+'&target=redirect-info'
    let redirect_info = await fetchData(redirect_info_api_url)
    setRedirectInfo(redirect_info?.data)
    if(selected_types_response?.data?.msg === 'No Reports Found')
    {
      setNoReports(true);
      return;
    }
    let selected_types = selected_types_response['data']['selected_types']
    let audit_date = selected_types_response['data']['created_at']
    let audit_url = selected_types_response['data']['url'] 
    
    let isDisplayShowMore = selected_types_response['data']['isDisplayShowMore']   
    setIsShowRecommendations(isDisplayShowMore)
    setIsBulk(selected_types_response['data']['bulk'])

    setAuditUrl(audit_url)
    setAuditDate(audit_date)
    setSelectedAudit(selected_types)
    setLoaderTxt('we are auditing your web page '+ audit_url + '.')
    return
  }
  else{
    let completed_audit = []
    if(percentage !== 100)
    {
      let check_progress_api_url = api_url+'?result='+report_id+'&target=progress'
      let completed_types_response = await fetchData(check_progress_api_url,renderCount,setRenderCount)
      completed_audit = (completed_types_response['data']['completed_types'] !== '') ? completed_types_response['data']['completed_types'].split(','): [] ;
      percentage = completed_types_response['data']['percentage'];
      setPercentage(percentage)
    }
    else
    {
      completed_audit = selectedAudit
    }
    let api_completed_audits = completed_audit
    let report_api_url = api_url+'?result='+report_id+'&target=';
    if(completedAudit.length !== 0)
    {
      completed_audit = getNotUpdatedReports(completed_audit,completedAudit)
    }

    if(completed_audit.length > 0)
      await setComponenetsData(completed_audit,setReports,report_api_url,setCompletedAudit,toast,audits,setMainTabIndex,setSubTabIndex);

    let created_at = (new Date().getTime() - new Date(auditDate).getTime())/1000;
    // no need to intiate re-render if all the audits updated 
    if(selectedAudit.length === completedAudit.length)
    {
      setIsReportFullyLoaded(true)
      toast.success('All reports are completed!',{
        autoClose:false
      })
      return
    }
    else
    {
      // audit partially completed and audit was intiated more the 3 minutes - so not intiated the re-render
      if(percentage !== 100 && created_at > 300 && api_completed_audits.length === completedAudit.length)
      {
        let not_completed_audits = getNotUpdatedReports(selectedAudit,completedAudit)
        for(let i = 0; i < not_completed_audits.length; i++)
        {
          toast.warn(audits[not_completed_audits[i]])
        }
        const toastContent = 'Audit was partially completed('+completedAudit.length+'/'+selectedAudit.length+'). But it does not stop you to check the completed audits. May be your web page having more problems, so our API will take time to audit. Please refresh the page after sometime or try a new test. If you still have partially completed audit issue, please "Talk to our expert", we are happy to help.'

        const partially_completed = () => (
          <div>
            <p>
            Audit was partially completed({completedAudit.length}/{selectedAudit.length}). But it does not stop you to check the completed audits. <b>May be your web page having more problems</b>, so our API will take time to audit. Please refresh the page after sometime or try a new test. If you still have partially completed audit issue, please schedule a meeting at your convenient time to talk with our experts. we are happy to help. <div style={{margin:"4% 0 0 18%"}} className="footer-sticky-contact-button"><a className="btn" href={redirectInfo.cta_button_link}>Talk To Our Expert</a></div>
            </p>
          </div>
        );
        toast(partially_completed,{autoClose:false})
        setIsReportFullyLoaded(true)
        return
      }

      // audit in progress - so intiate re-render
      if(percentage !== 100 && created_at < 300)
      {
        await sleep(5000)
        setRenderCount(renderCount + 1)
        return
      }

    }

    // 100% completed but failed to fetch some of the reports - so intiate re-render
    if(percentage === 100 && selectedAudit.length !== completedAudit.length)
    {
      await sleep(2000)
      setRenderCount(renderCount + 1)
      return
    }

    setRenderCount(renderCount+1)
    
  }

  return

}

async function setComponenetsData(completedAudits,setReports,api_url,setCompletedAudit,toast,audits,setMainTabIndex,setSubTabIndex){

    let p = [], r;

    for(let i = 0; i < completedAudits.length; i++)
    {
      r = fetchReport(api_url,completedAudits[i],setReports,setCompletedAudit,toast,audits,setMainTabIndex,setSubTabIndex)
      p.push(r)
    }

    return Promise.all(p)
    .then(function (results) {
      //console.log('All promises completed')
    });
}

function fetchReport(api_url,type,setReports,setCompletedAudit,toast,audits,setMainTabIndex,setSubTabIndex){
  let c = []

  let r = axios.get(api_url+type).then(function(d){
    
    setReports(previousState => {
      c.push(type)
      setCompletedAudit(previousState => {
        return [ ...previousState, ...c ]
      });
      toast.success(audits[type])
      if(type === 'psi-mobile')
      {
        return { ...previousState, mobile:{...previousState.mobile,...d['data']['mobile']} }
      }
      else if(type === 'psi-desktop')
      {
        return { ...previousState, desktop:{...previousState.desktop,...d['data']['desktop']} }
      }
      else if(type === 'ser')
      {
        return { ...previousState, ...d['data']}
      }
      else if(type === 'sec')
      {
        return { ...previousState, ...d['data']}
      }
      else if(type === 'ylb-mobile')
      {
        return { ...previousState, mobile:{...previousState.mobile,...d['data']['mobile']} }
      }
      else if(type === 'ylb-desktop')
      {
        return { ...previousState, desktop:{...previousState.desktop,...d['data']['desktop']} }
      }
  
    });
  }).catch(function(error){
    // toast.warn(audits[type]+' - fetching report failed. We will retry it now.')
  })
  return r
}

export default getData
